import {combineReducers, compose, createStore, Store} from "redux";
import raffleReducer from "./raffle/raffleReducer";
import {RaffleState} from "./raffle/types";

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export interface ApplicationState {
    raffle: RaffleState,
}

const store: Store<ApplicationState> = createStore(
    combineReducers<ApplicationState>({
        raffle: raffleReducer,
    }),
    composeEnhancers()
);

export default store;