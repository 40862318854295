import * as React from "react";
import {Col, Row, Typography} from "antd";
import Contestant from "../../model/Contestant";

let interval: any = null;

export class ExitingResultsApi {
    private subscribers: ((winner: number) => void)[] = [];
    subscribe = (handler: (winner: number) => void) => {
        this.subscribers.push(handler);
    };
    play = (winner: number) => {
        this.subscribers.forEach(s => s(winner));
    };
}

interface Props {
    onEnd: () => any
    contestants: Contestant[]
    exitingResultsApi: ExitingResultsApi
}

interface State {
    time: number
    current: number
    fps: number
    isRunning: boolean
    options: number[]
}

class ExitingResults extends React.Component<Props, State> {

    state: State = {
        time: 0,
        current: 0,
        fps: 0,
        isRunning: false,
        options: [],
    };

    componentDidMount(): void {
        const {exitingResultsApi} = this.props;
        exitingResultsApi.subscribe(this.play);
    }

    private shuffleArray = (array: number[]): number[] => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    };

    play = (winner: number): void => {
        const {onEnd, contestants} = this.props;
        const options: number[] = this.shuffleArray(
            contestants.map(c => c.number)
        );
        this.setState({
            time: 0,
            current: 0,
            fps: 0,
            isRunning: true,
            options: options,
        }, () => {
            const mainInterval = setInterval(() => {
                clearInterval(interval);
                const ac = Math.log2(this.state.time + 1) * 30;
                if (this.state.fps + ac < 500) {
                    interval = setInterval(() => {
                        this.setState({current: this.state.current + 1});
                    }, (this.state.fps + ac));
                    this.setState({
                        time: this.state.time + 1,
                        fps: this.state.fps + ac
                    });
                } else {
                    clearInterval(mainInterval);
                    const focusInterval = setInterval(() => {
                        if (options[(this.state.current + 4) % options.length] !== winner) {
                            this.setState({current: this.state.current + 1});
                        } else {
                            clearInterval(focusInterval);
                            setTimeout(() => {
                                this.setState({current: this.state.current + 1});
                                setTimeout(() => {
                                    this.setState({current: this.state.current + 1});
                                    setTimeout(() => {
                                        this.setState({
                                            time: 0,
                                            isRunning: false,
                                        }, () => onEnd());
                                    }, 2000);
                                }, 300);
                            }, 300);
                        }
                    }, 400);
                }
            }, 1000);
        });
    };

    render() {
        const {options} = this.state;
        return (
            <Row>
                <Col xs={24}>
                    {this.state.isRunning ?
                        <Row type="flex" justify='center' align="middle">
                            <Col sm={{span: 1, offset: 0}}
                                 xs={{span: 10, offset: 6}}
                                 style={{opacity: 0.2}}>
                                <Typography.Text
                                    style={{fontSize: 10}}>{options[this.state.current % options.length]}</Typography.Text>
                            </Col>
                            <Col sm={{span: 2, offset: 3}}
                                 xs={{span: 10, offset: 6}}
                                 style={{opacity: 0.6}}>
                                <Typography.Text
                                    style={{fontSize: 20}}>{options[(this.state.current + 1) % options.length]}</Typography.Text>
                            </Col>
                            <Col sm={{span: 4, offset: 4}}
                                 xs={{span: 10, offset: 6}}>
                                <Typography.Text strong
                                                 style={{
                                                     fontSize: 30,
                                                     opacity: 1
                                                 }}>{options[(this.state.current + 2) % options.length]}</Typography.Text>
                            </Col>
                            <Col sm={{span: 2, offset: 4}}
                                 xs={{span: 10, offset: 6}}
                                 style={{opacity: 0.6}}>
                                <Typography.Text
                                    style={{fontSize: 20}}>{options[(this.state.current + 3) % options.length]}</Typography.Text>
                            </Col>
                            <Col sm={{span: 1, offset: 3}}
                                 xs={{span: 10, offset: 6}}
                                 style={{opacity: 0.2}}>
                                <Typography.Text
                                    style={{fontSize: 10}}>{options[(this.state.current + 4) % options.length]}</Typography.Text>
                            </Col>
                        </Row> : null}
                </Col>
            </Row>
        );
    }
}

export default ExitingResults;
