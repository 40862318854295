import Contestant from "../model/Contestant";
import Prize from "../model/Prize";
import PrizeAssignment from "../model/PrizeAssignment";

export const NEW_WINNER = 'NEW_WINNER';
export const ADD_CONTESTANT = 'ADD_CONTESTANT';
export const ADD_PRIZE = 'ADD_PRIZE';

export interface RaffleState {
    contestants: Contestant[]
    prizes: Prize[]
    winners: PrizeAssignment[]
}

interface NewWinnerAction {
    type: typeof NEW_WINNER
    prizeAssignment: PrizeAssignment
}

interface AddContestantAction {
    type: typeof ADD_CONTESTANT
    contestant: Contestant
}

interface AddPrizeAction {
    type: typeof ADD_PRIZE
    prize: Prize
}

export type RaffleActionTypes =
    NewWinnerAction
    | AddContestantAction
    | AddPrizeAction