import * as React from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.css';
import {ConfigProvider} from "antd";
import esES from 'antd/es/locale/es_ES';
import App from "./App";
import {Provider} from "react-redux";
import store from "./store";

ReactDOM.render(
    <ConfigProvider locale={esES}>
        <Provider store={store}>
            <App/>
        </Provider>
    </ConfigProvider>,
    document.getElementById('root')
);
