/**
 * Created by elbraulio 24-12-19
 */
import Contestant from "../model/Contestant";
import Prize from "../model/Prize";
import PrizeAssignment from "../model/PrizeAssignment";
import {
    ADD_CONTESTANT,
    ADD_PRIZE,
    NEW_WINNER,
    RaffleActionTypes
} from "./types";


export function newWinner(prizeAssignment: PrizeAssignment): RaffleActionTypes {
    return {
        type: NEW_WINNER,
        prizeAssignment: prizeAssignment,
    }
}

export function addContestant(contestant: Contestant): RaffleActionTypes {
    return {
        type: ADD_CONTESTANT,
        contestant: contestant,
    }
}

export function addPrize(prize: Prize): RaffleActionTypes {
    return {
        type: ADD_PRIZE,
        prize: prize,
    }
}
