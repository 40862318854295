import * as React from 'react';
import Contestant from "../../model/Contestant";
import PrizeAssignment from "../../model/PrizeAssignment";
import {connect} from "react-redux";
import Prize from "../../model/Prize";
import {ApplicationState} from "../../store";
import {newWinner} from "../raffleActions";
import ExitingResults, {ExitingResultsApi} from "./ExitingResults";
import {Card, Col, Row, Typography} from "antd";

export class ResultBoxApi {
    private subscribers: ((winner: number) => void)[] = [];
    subscribe = (handler: (winner: number) => void) => {
        this.subscribers.push(handler);
    };
    startDraw = (winner: number) => {
        this.subscribers.forEach(s => s(winner))
    };
}

interface State {
    exitingResultsApi: ExitingResultsApi
    isPlaying: boolean
    winner: number | undefined
}

interface Props {
    resultBoxApi: ResultBoxApi
    onEnd: () => any
    contestants: Contestant[]
    prize: Prize
    onNewWinner: (winner: PrizeAssignment) => void
}

class ResultBox extends React.Component<Props, State> {

    state: State = {
        exitingResultsApi: new ExitingResultsApi(),
        isPlaying: false,
        winner: undefined
    };

    private showWinner = (winner: number) => {
        const {exitingResultsApi} = this.state;
        this.setState(
            {isPlaying: true},
            () => {
                exitingResultsApi.play(winner);
                this.setState({winner});
            }
        )
    };

    private renderTitle = () => {
        const {prize} = this.props;
        return (
            <span>
                <Typography.Text type='secondary'>
                    {`${prize.drawNumber}º`}
                </Typography.Text>
                <Typography.Text strong style={{paddingLeft: 10}}>
                    {prize.name}
                </Typography.Text>
            </span>
        );
    };

    componentDidMount(): void {
        const {resultBoxApi} = this.props;
        resultBoxApi.subscribe(this.showWinner);
    }

    render() {
        const {exitingResultsApi, isPlaying, winner} = this.state;
        const {contestants} = this.props;
        return (
            <Card title={this.renderTitle()}>
                <Row>
                    <Col xs={12}>
                        {isPlaying ? <ExitingResults
                                exitingResultsApi={exitingResultsApi}
                                onEnd={() => {
                                    this.setState({isPlaying: false})
                                }}
                                contestants={contestants}/>
                            : <Typography.Text strong
                                               style={{
                                                   fontSize: 30,
                                                   opacity: 1
                                               }}>
                                {winner !== undefined ? winner : '?'}
                            </Typography.Text>}
                    </Col>
                </Row>
            </Card>
        );
    }
}

export default connect(
    (state: ApplicationState) => {
        return {}
    },
    dispatch => {
        return {
            onNewWinner: (ps: PrizeAssignment) => dispatch(newWinner(ps))
        }
    }
)(ResultBox);