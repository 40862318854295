import {
    ADD_CONTESTANT,
    ADD_PRIZE,
    NEW_WINNER,
    RaffleActionTypes,
    RaffleState
} from "./types";

const initialState: RaffleState = {
    contestants: [],
    prizes: [],
    winners: [],
};

export default function raffleReducer(
    state = initialState,
    action: RaffleActionTypes
): RaffleState {
    switch (action.type) {
        case NEW_WINNER:
            return {
                ...state,
                winners: [...state.winners, action.prizeAssignment]
            };
        case ADD_CONTESTANT:
            return {
                ...state,
                contestants: [...state.contestants, action.contestant],
            };
        case ADD_PRIZE:
            return {
                ...state,
                prizes: [...state.prizes, action.prize],
            };
        default:
            return state;
    }
}