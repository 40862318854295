import * as React from "react";
import {Button, Col, Row} from "antd";
import ContestantTable from "./raffle/contestantTable/ContestantTable";
import PrizeTableAndResults, {PrizeTableAndResultsApi} from "./raffle/prizeTableAndResults/PrizeTableAndResults";
import Version from "./Version";
import {connect} from "react-redux";
import {ApplicationState} from "./store";

interface State {
    prizeTableAndResultsApi: PrizeTableAndResultsApi
}

interface Props {
    drawNumber: number
}

class App extends React.Component<Props, State> {

    state: State = {
        prizeTableAndResultsApi: new PrizeTableAndResultsApi()
    };

    render() {
        const {prizeTableAndResultsApi} = this.state;
        const {drawNumber} = this.props;
        return (
            <Row style={{padding: 50}}>
                <Col xs={24}>
                    <Row type="flex" justify="space-between" align="bottom"
                         gutter={[50, 50]}>
                        <Col sm={12} xs={24}>
                            <ContestantTable raffle={1}/>
                        </Col>
                        <Col sm={12} xs={24}>
                            <PrizeTableAndResults
                                prizeTableAndResultsApi={prizeTableAndResultsApi}/>
                        </Col>
                    </Row>
                    {drawNumber > 0 ? <Row style={{paddingTop: 50}}>
                        <Col xs={3}>
                            <Button size='large'
                                    type='danger'
                                    onClick={() => {
                                        prizeTableAndResultsApi.draw()
                                    }}>
                                {`Sortear ${drawNumber}º`}
                            </Button>
                        </Col>
                    </Row> : null}
                    <Row style={{paddingTop: 50}}>
                        <Col xs={24}>
                            <Version/>
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }
}

export default connect(
    (state: ApplicationState) => {
        const {prizes, winners, contestants} = state.raffle;
        let drawNumber = 0;
        if (prizes.length > 0 && contestants.length > 0) {
            drawNumber = winners.length + 1;
        }
        if (winners.length === prizes.length) {
            drawNumber = 0;
        }
        return {
            drawNumber: drawNumber,
        }
    }
)(App);