import * as React from "react";
import {Button, Col, Collapse, Icon, Input, Row, Typography} from "antd";
import {connect} from "react-redux";
import {addContestant} from "../raffleActions";
import Contestant from "../../model/Contestant";
import {ApplicationState} from "../../store";

interface Props {
    raffle: number
    contestants: Contestant[]
    onAddContestant: (contestant: Contestant) => void
}

interface State {
    newNumber: number | undefined;
    lockInput: boolean;
}

class ContestantTable extends React.Component<Props, State> {

    state: State = {
        newNumber: undefined,
        lockInput: false,
    };

    renderNumbers = () => {
        const {contestants} = this.props;
        return (
            <Collapse bordered={false}
                      defaultActiveKey={['1']}
                      expandIcon={({isActive}) =>
                          isActive
                              ? <Icon type='eye-invisible'/>
                              : <Icon type='eye'/>}>
                <Collapse.Panel header={""}
                                key="1"
                                style={{border: 0,}}>
                    <ul>
                        {contestants.map((c: Contestant) => (
                            <li key={c.number}>
                                <Typography.Text>{c.number}</Typography.Text>
                            </li>
                        ))}
                    </ul>
                </Collapse.Panel>
            </Collapse>
        );
    };

    addNumber = (): void => {
        const {newNumber} = this.state;
        const {contestants, onAddContestant} = this.props;
        if (newNumber === undefined) return;
        const exists = contestants.reduce(
            (p: boolean, c: Contestant) => p || c.number === newNumber,
            false
        );
        if (exists) {
            alert('No se puede repetir 😅');
        } else {
            onAddContestant({number: newNumber});
            this.setState({newNumber: undefined,});
        }
    };

    updateNumber = (e: any) => {
        e.preventDefault();
        const value = e.target.value;
        this.setState({
            newNumber: value === '' ? undefined : parseInt(value),
        });
    };

    render() {
        const {lockInput, newNumber} = this.state;
        return (
            <Row>
                <Col xs={24}>
                    <Row>
                        <Col xs={24}>
                            <Typography.Title level={2}>
                                Números participantes
                                <span role='img' aria-label=':)'> 🤑</span>
                            </Typography.Title>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24}>
                            {this.renderNumbers()}
                        </Col>
                    </Row>
                    {!lockInput ? <Row>
                        <Col xs={20}>
                            <Input placeholder="Ingresa un número para Jugar!"
                                   onChange={this.updateNumber}
                                   type='number'
                                   value={newNumber}
                                   onPressEnter={this.addNumber}/>
                        </Col>
                        <Col xs={3} offset={1}>
                            <Button shape="circle" icon="plus"
                                    onClick={this.addNumber}/>
                        </Col>
                    </Row> : null}
                </Col>
            </Row>
        );
    }
}

export default connect(
    (state: ApplicationState) => {
        return {
            contestants: state.raffle.contestants,
        }
    },
    dispatch => {
        return {
            onAddContestant: (contestant: Contestant) => dispatch(
                addContestant(contestant)
            ),
        }
    }
)(ContestantTable);