import * as React from "react";
import {Button, Col, Input, Row, Typography} from "antd";
import {connect} from "react-redux";
import {ApplicationState} from "../../store";
import Contestant from "../../model/Contestant";
import PrizeAssignment from "../../model/PrizeAssignment";
import ResultBox, {ResultBoxApi} from "../results/ResultBox";
import Prize from "../../model/Prize";
import {addPrize, newWinner} from "../raffleActions";

export class PrizeTableAndResultsApi {
    subscribers: (() => void)[] = [];
    subscribe = (handler: () => void) => {
        this.subscribers.push(handler);
    };
    draw = () => {
        this.subscribers.forEach(s => s());
    };
}

interface Props {
    contestants: Contestant[]
    winners: PrizeAssignment[]
    prizes: Prize[]
    onNewPrize: (prize: Prize) => void
    onNewWinner: (winner: PrizeAssignment) => void
    prizeTableAndResultsApi: PrizeTableAndResultsApi
}

interface State {
    newPrize: string | undefined
    resultBoxApis: ResultBoxApi[]
    currentDraw: number
}

class PrizeTableAndResults extends React.Component<Props, State> {

    state: State = {
        newPrize: undefined,
        resultBoxApis: [],
        currentDraw: 0,
    };

    private makeOptions = (): Contestant[] => {
        const {contestants, winners} = this.props;
        const set: Set<number> = new Set<number>();
        winners.forEach((w: PrizeAssignment) => {
            set.add(w.contestant.number)
        });
        return contestants.filter((c: Contestant) => !set.has(c.number));
    };

    private draw = (): void => {
        const {currentDraw} = this.state;
        const {prizes} = this.props;
        const options: Contestant[] = this.makeOptions();
        const index = Math.floor(Math.random() * options.length);
        this.state.resultBoxApis[currentDraw].startDraw(options[index].number);
        this.props.onNewWinner({
            contestant: options[index],
            prize: prizes[currentDraw]
        });
        this.setState({currentDraw: currentDraw + 1})
    };

    componentDidMount(): void {
        this.props.prizeTableAndResultsApi.subscribe(this.draw);
    }

    private renderResults = () => {
        const {prizes, contestants} = this.props;
        if (prizes.length === 0)
            return null;
        else
            return prizes.map((p: Prize, index: number) => {
                return (<Col xs={12} key={index}>
                    <ResultBox contestants={contestants}
                               resultBoxApi={this.state.resultBoxApis[index]}
                               onEnd={() => {
                               }}
                               prize={p}/>
                </Col>)
            });
    };

    private addPrize = (): void => {
        const {newPrize, resultBoxApis} = this.state;
        const {onNewPrize, prizes} = this.props;
        if (!newPrize) return;
        const boxAPi = new ResultBoxApi();
        resultBoxApis.push(boxAPi);
        onNewPrize({drawNumber: prizes.length + 1, name: newPrize});
        this.setState({newPrize: undefined, resultBoxApis: resultBoxApis});
    };

    private updatePrize = (e: any) => {
        e.preventDefault();
        this.setState({newPrize: e.target.value});
    };

    render() {
        const {newPrize} = this.state;
        const {prizes} = this.props;
        return (
            <Row>
                <Col xs={24}>
                    <Row>
                        <Col xs={24}>
                            <Typography.Title level={2}>
                                Premios a sortear
                                <span role='img' aria-label=':)'> 🎰</span>
                            </Typography.Title>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        {this.renderResults()}
                    </Row>
                    <Row>
                        <Col xs={20}>
                            <Input placeholder="Ingresa un Premio"
                                   onChange={this.updatePrize}
                                   type='text'
                                   value={newPrize}
                                   onPressEnter={this.addPrize}
                                   prefix={`${prizes.length + 1}º`}/>
                        </Col>
                        <Col xs={3} offset={1}>
                            <Button shape="circle" icon="plus"
                                    onClick={this.addPrize}/>
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }
}

export default connect(
    (state: ApplicationState) => {
        return {
            contestants: state.raffle.contestants,
            winners: state.raffle.winners,
            prizes: state.raffle.prizes,
        }
    },
    dispatch => {
        return {
            onNewPrize: (prize: Prize) => dispatch(addPrize(prize)),
            onNewWinner: (winner: PrizeAssignment) => dispatch(newWinner(winner)),
        }
    }
)(PrizeTableAndResults);